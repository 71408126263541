import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Messages from "../enums/Messages";
import FFZDatatable from "./DashboardDatatable/FFZ_Datatable";
import KFZDatatable from "./DashboardDatatable/KFZ_Datatable";
import ALLDatatable from "./DashboardDatatable/ALL_Datatable";
import LKWDatatable from "./DashboardDatatable/LKW_Datatable";
import OldNachweiseDatatable from "./DashboardDatatable/OldNachweiseDatatable";
import imgThumbnail from "../includes/img/ThumbnailStar.png";
import LoadingScreen from "./LoadingScreen";
import LoadingState from "../enums/LoadingState";
import LoadingMessages from "../enums/LoadingMessages";
import NoFSDatatable from "./DashboardDatatable/No_FS_Datatable";
import NO_UPLOAD from "./DashboardDatatable/NO_UPLOAD";

const AdminDashboard = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");

  const [showlist, setShowList] = useState({ page: 3, pagename: "für alle Führerescheinarten" });
  const [receivedData, setReceivedData] = useState();
  const [receivedData2, setReceivedData2] = useState();
  const [receivedNeedUpdateData, setReceivedNeedUpdateData] = useState([]);
  const [receivedNeedUpdateData2, setReceivedNeedUpdateData2] = useState([]);
  const [loading, setLoading] = useState({ state: LoadingState.Inactive, text: LoadingMessages.CheckAuthorization });
  const [galopen, setGalOpen] = useState({ gal: false, url: undefined });

  useEffect(() => {
    getData();
  }, []);

  const lightboxHandler = async (pnr, mode) => {
    setGalOpen({ ...galopen, gal: true, url: await getlightboxImage(pnr, mode) });
    setLoading({ ...loading, state: LoadingState.Inactive });
  };

  const getlightboxImage = async (pnr, mode) => {
    setLoading({ state: LoadingState.Active, text: LoadingMessages.GeneralWaiting2 });
    return axios
      .get(process.env.REACT_APP_BASE_URL + `filesUpload/getImage.php?pnr=${pnr}&mode=${mode}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        alert(Messages.error.message);
      });
  };

  const setImageInDatatable = (el) => {
    const pnr = el.personalnummer;
    if (el.mode === "KFZ+FFZ") {
      return {
        ...el,
        ffzVorder: (
          <img
            src={imgThumbnail}
            alt="Flurförder Führerschein Bild Vorderseite"
            width={"50px"}
            onClick={() => lightboxHandler(pnr, "ffzV")}
            style={{ textAlign: "center", cursor: "pointer" }}
          />
        ),
        ffzRueck: (
          <img
            src={imgThumbnail}
            alt="Flurförder Führerschein Bild Rückseite"
            width={"50px"}
            onClick={() => lightboxHandler(pnr, "ffzR")}
            style={{ cursor: "pointer" }}
          />
        ),
        kfzVorder: (
          <img
            src={imgThumbnail}
            alt="KFZ Führereschein Bild Vorderseite"
            width={"50px"}
            onClick={() => lightboxHandler(pnr, "kfzV")}
            style={{ textAlign: "center", cursor: "pointer" }}
          />
        ),
        kfzRueck: (
          <img
            src={imgThumbnail}
            alt="KFZ Führereschein Bild Rückseiteseite"
            width={"50px"}
            onClick={() => lightboxHandler(pnr, "kfzR")}
            style={{ cursor: "pointer" }}
          />
        ),
      };
    } else if (el.mode === "KFZ") {
      return {
        ...el,
        kfzVorder: (
          <img
            src={imgThumbnail}
            alt="KFZ Führereschein Bild Vorderseite"
            width={"50px"}
            onClick={() => lightboxHandler(pnr, "kfzV")}
            style={{ textAlign: "center", cursor: "pointer" }}
          />
        ),
        kfzRueck: (
          <img
            src={imgThumbnail}
            alt="KFZ Führerschein Bild Rückseite"
            width={"50px"}
            onClick={() => lightboxHandler(pnr, "kfzR")}
            style={{ cursor: "pointer" }}
          />
        ),
      };
    } else if (el.mode === "FFZ") {
      return {
        ...el,
        ffzVorder: (
          <img
            src={imgThumbnail}
            alt="Flurförder Führerschein Bild Vorderseite"
            width={"50px"}
            onClick={() => lightboxHandler(pnr, "ffzV")}
            style={{ textAlign: "center", cursor: "pointer" }}
          />
        ),
        ffzRueck: (
          <img
            src={imgThumbnail}
            alt="Flurförder  Führerschein Bild Rückseite"
            width={"50px"}
            onClick={() => lightboxHandler(pnr, "ffzR")}
            style={{ cursor: "pointer" }}
          />
        ),
      };
    } else if (el.mode === "KEIN FS") {
      return el;
    }
  };

  const mapRows = (data) => {
    return data.map(setImageInDatatable);
  };

  const liClickHandler = async (e) => {
    setShowList({ ...showlist, page: e.target.value, pagename: e.target.getAttribute("name") });
    switch (e.target.value) {
      case 1:
        document.getElementById(1).className = "fw-bolder mt-1";
        document.getElementById(2).className = "my-1";
        document.getElementById(3).className = "mb-1";
        document.getElementById(4).className = "mt-1";
        document.getElementById(5).className = "my-1";
        document.getElementById(6).className = "my-1";
        document.getElementById(7).className = "mb-1";
        document.getElementById(8).className = "mb-1";
        document.getElementById(9).className = "mb-1";
        document.getElementById(10).className = "mb-1";
        break;
      case 2:
        document.getElementById(1).className = "mt-1";
        document.getElementById(2).className = "fw-bolder my-1";
        document.getElementById(3).className = "mb-1";
        document.getElementById(4).className = "mt-1";
        document.getElementById(5).className = "my-1";
        document.getElementById(6).className = "my-1";
        document.getElementById(7).className = "mb-1";
        document.getElementById(8).className = "mb-1";
        document.getElementById(9).className = "mb-1";
        document.getElementById(10).className = "mb-1";
        break;
      case 3:
        document.getElementById(1).className = "mt-1";
        document.getElementById(2).className = "my-1";
        document.getElementById(3).className = "fw-bolder mb-1";
        document.getElementById(4).className = "mt-1";
        document.getElementById(5).className = "my-1";
        document.getElementById(6).className = "my-1";
        document.getElementById(7).className = "mb-1";
        document.getElementById(8).className = "mb-1";
        document.getElementById(9).className = "mb-1";
        document.getElementById(10).className = "mb-1";
        break;
      case 4:
        document.getElementById(1).className = "mt-1";
        document.getElementById(2).className = "my-1";
        document.getElementById(3).className = "mb-1";
        document.getElementById(4).className = "fw-bolder mt-1";
        document.getElementById(5).className = "my-1";
        document.getElementById(6).className = "my-1";
        document.getElementById(7).className = "mb-1";
        document.getElementById(8).className = "mb-1";
        document.getElementById(9).className = "mb-1";
        document.getElementById(10).className = "mb-1";
        break;
      case 5:
        document.getElementById(1).className = "mt-1";
        document.getElementById(2).className = "my-1";
        document.getElementById(3).className = "mb-1";
        document.getElementById(4).className = "mt-1";
        document.getElementById(5).className = "fw-bolder my-1";
        document.getElementById(6).className = "my-1";
        document.getElementById(7).className = "mb-1";
        document.getElementById(8).className = "mb-1";
        document.getElementById(9).className = "mb-1";
        document.getElementById(10).className = "mb-1";
        break;
      case 6:
        await getNeedUpdateData("PKW");
        document.getElementById(1).className = "mt-1";
        document.getElementById(2).className = "my-1";
        document.getElementById(3).className = "mb-1";
        document.getElementById(4).className = "mt-1";
        document.getElementById(5).className = "my-1";
        document.getElementById(6).className = "fw-bolder my-1";
        document.getElementById(7).className = "mb-1";
        document.getElementById(8).className = "mb-1";
        document.getElementById(9).className = "mb-1";
        document.getElementById(10).className = "mb-1";
        break;
      case 7:
        await getNeedUpdateData("LKW");
        document.getElementById(1).className = "mt-1";
        document.getElementById(2).className = "my-1";
        document.getElementById(3).className = "mb-1";
        document.getElementById(4).className = "mt-1";
        document.getElementById(5).className = "my-1";
        document.getElementById(6).className = "my-1";
        document.getElementById(7).className = "fw-bolder mb-1";
        document.getElementById(8).className = "mb-1";
        document.getElementById(9).className = "mb-1";
        document.getElementById(10).className = "mb-1";
        break;
      case 8:
        document.getElementById(1).className = "mt-1";
        document.getElementById(2).className = "my-1";
        document.getElementById(3).className = "mb-1";
        document.getElementById(4).className = "mt-1";
        document.getElementById(5).className = "my-1";
        document.getElementById(6).className = "my-1";
        document.getElementById(7).className = "my-1";
        document.getElementById(8).className = "fw-bolder my-1";
        document.getElementById(9).className = "mb-1";
        document.getElementById(10).className = "mb-1";
        break;
      case 9:
        document.getElementById(1).className = "mt-1";
        document.getElementById(2).className = "my-1";
        document.getElementById(3).className = "mb-1";
        document.getElementById(4).className = "mt-1";
        document.getElementById(5).className = "my-1";
        document.getElementById(6).className = "my-1";
        document.getElementById(7).className = "my-1";
        document.getElementById(8).className = "my-1";
        document.getElementById(9).className = "fw-bolder mb-1";
        document.getElementById(10).className = "my-1";
        break;
      case 10:
        getNoUploadUser();
        document.getElementById(1).className = "mt-1";
        document.getElementById(2).className = "my-1";
        document.getElementById(3).className = "mb-1";
        document.getElementById(4).className = "mt-1";
        document.getElementById(5).className = "my-1";
        document.getElementById(6).className = "my-1";
        document.getElementById(7).className = "my-1";
        document.getElementById(8).className = "my-1";
        document.getElementById(9).className = "my-1";
        document.getElementById(10).className = "fw-bolder mb-1";
        break;
      default:
        break;
    }
  };

  const getData = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "filesUpload/filesUpload.php", { headers: { Authorization: "Bearer " + token} })
      .then((response) => {
        setReceivedData(mapRows(response.data));
      })
      .catch((error) => {
        alert(Messages.error.message);
      });
  };

  const getNeedUpdateData = async (mode) => {
    setLoading({ state: LoadingState.Active, text: LoadingMessages.GeneralWaiting3 });
    return axios
      .get(process.env.REACT_APP_BASE_URL + `needUpdateData/needUpdateData.php?mode=${mode}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        if (mode === "PKW") {
          setReceivedNeedUpdateData(response.data);
        } else {
          setReceivedNeedUpdateData2(response.data);
        }
        setLoading({ ...loading, state: LoadingState.Inactive });
      })
      .catch((error) => {
        alert(Messages.error.message);
        setLoading({ ...loading, state: LoadingState.Inactive });
      });
  };

  const getNoUploadUser = async () => {
    setLoading({ state: LoadingState.Active, text: LoadingMessages.GeneralWaiting3 });
    return axios
      .get(process.env.REACT_APP_BASE_URL + "needUpdateData/noUpload.php", { headers: { Authorization: "Bearer " + token } })
      .then((response) => {
        setReceivedData2(response.data);
        setLoading({ ...loading, state: LoadingState.Inactive });
      })
      .catch((error) => {
        alert(Messages.error.message);
        setLoading({ ...loading, state: LoadingState.Inactive });
      });
  };

  const filter = (el) => {
    switch (showlist.page) {
      case 1:
        return el.mode === "KFZ" || el.mode === "KFZ+FFZ";
      case 2:
        return el.mode === "FFZ" || el.mode === "KFZ+FFZ";
      case 3:
        return el.personalnummer !== "" && el.mode !== "KEIN FS";
      case 4:
        return el.ffzRueck !== null && el.ffzRueck !== "" && el.kfzRueck !== null && el.kfzRueck !== "" && el.mode !== "KEIN FS";
      case 5:
        return el.lkw === "ja";

      case 8:
        return el.mode === "KEIN FS";
      case 9:
        return el.mode === "FFZ";
      default:
        break;
    }
  };

  const filterReceivedData = () => {
    return receivedData.filter(filter);
  };

  const filterReceivedNeedUpdateData = () => {
    return receivedNeedUpdateData && receivedNeedUpdateData.filter(filter);
  };

  return (
    <>
      {loading.state === LoadingState.Active && <LoadingScreen text={loading.text} />}
      <div className="row mx-5">
        <div className="col-md-3 mt-1" style={{ border: "1px solid black", backgroundColor: "aliceblue", height: "93vh" }}>
          <h5 className=" mt-3" style={{ textDecoration: "underline", cursor: "default" }}>
            Führerschein Nachweis Wackenhut
          </h5>
          <ul className="listeFührerscheine">
            <li
              id="3"
              onClick={liClickHandler}
              className="fw-bolder mb-1"
              style={{ cursor: "pointer" }}
              value="3"
              name="für alle Führerscheinarten"
            >
              Alle Nachweise
            </li>
            <li id="1" onClick={liClickHandler} className="mt-1" style={{ cursor: "pointer" }} value="1" name="KFZ">
              PKW
            </li>
            <li id="5" onClick={liClickHandler} className="my-1" style={{ cursor: "pointer" }} value="5" name="LKW">
              LKW
            </li>
            <li
              id="2"
              onClick={liClickHandler}
              className="my-1"
              style={{ cursor: "pointer" }}
              value="2"
              name="Flurförderfahrzeuge"
            >
              Flurförderfahrzeuge
            </li>
            <li id="8" onClick={liClickHandler} className="my-1" style={{ cursor: "pointer" }} value="8" name="Ohne Führerschein">
              Ohne Führerschein
            </li>
            <li id="10" onClick={liClickHandler} className="my-1" style={{ cursor: "pointer" }} value="10" name="kein Upload">
              Mitarbeiter ohne Upload
            </li>
          </ul>
          <br />
          <h5 className=" mt-3" style={{ textDecoration: "underline", cursor: "default" }}>
            Veraltete Nachweise
          </h5>
          <ul className="listeFührerscheine">
            <li
              id="4"
              onClick={liClickHandler}
              className="mt-1"
              style={{ cursor: "pointer" }}
              value="4"
              name="aller veralteten Nachweise"
            >
              Alle veralteten Uploads
            </li>
            <li
              id="6"
              onClick={liClickHandler}
              className="my-1"
              style={{ cursor: "pointer" }}
              value="6"
              name="aller veralteten Nachweise"
            >
              Veraltete PKW Uploads
            </li>
            <li
              id="7"
              onClick={liClickHandler}
              className="mb-1"
              style={{ cursor: "pointer" }}
              value="7"
              name="aller veralteten Nachweise"
            >
              Veraltete LKW Uploads
            </li>
            <li
              id="9"
              onClick={liClickHandler}
              className="mb-1"
              style={{ cursor: "pointer" }}
              value="9"
              name="aller veralteten Nachweise"
            >
              Veraltete FFZ Uploads
            </li>
          </ul>
        </div>
        <div className="col-md-9 mt-1" style={{ border: "1px solid black", backgroundColor: "aliceblue" }}>
          <h5 className="mt-3" style={{ textDecoration: "underline", cursor: "default" }}>
            Nachweisliste {showlist.pagename}
          </h5>
          {showlist.page === 1 && receivedData && (
            <KFZDatatable pagenum={showlist.page} galopen={galopen} setGalOpen={setGalOpen} receivedData={filterReceivedData()} />
          )}
          {showlist.page === 5 && receivedData && (
            <LKWDatatable pagenum={showlist.page} galopen={galopen} setGalOpen={setGalOpen} receivedData={filterReceivedData()} />
          )}
          {showlist.page === 2 && receivedData && (
            <FFZDatatable pagenum={showlist.page} galopen={galopen} setGalOpen={setGalOpen} receivedData={filterReceivedData()} />
          )}
          {showlist.page === 3 && receivedData && (
            <ALLDatatable
              pagenum={showlist.page}
              pageNum={3}
              galopen={galopen}
              setGalOpen={setGalOpen}
              receivedData={filterReceivedData()}
            />
          )}
          {showlist.page === 8 && receivedData && (
            <NoFSDatatable galopen={galopen} setGalOpen={setGalOpen} receivedData={filterReceivedData()} />
          )}
          {showlist.page === 10 && receivedData && <NO_UPLOAD loading={loading} receivedData={receivedData2} />}
          {showlist.page === 4 && receivedData && (
            <OldNachweiseDatatable pagenum={showlist.page} receivedData={filterReceivedNeedUpdateData()} />
          )}
          {showlist.page === 6 && receivedData && (
            <OldNachweiseDatatable pagenum={showlist.page} receivedData={receivedNeedUpdateData} />
          )}
          {showlist.page === 7 && receivedData && (
            <OldNachweiseDatatable pagenum={showlist.page} receivedData={receivedNeedUpdateData2} />
          )}
          {showlist.page === 9 && receivedData && (
            <OldNachweiseDatatable pagenum={showlist.page} receivedData={filterReceivedNeedUpdateData()} />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
