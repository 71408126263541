import React from 'react'

const InsertSingelMa = (props) => {
    const {inputHandler,newMaData,submitHandler} = props

return (
    <div>
        <h3 className="mx-2 text-white">Bitte Füllen Sie alle Felder aus</h3>
        <br/>
        <label className="form-label mx-3" htmlFor="personalnummer">Personalnummer</label>
        <input id="personalnummer" name="personalnummer" type="text" className="form-control mx-3" onChange={inputHandler} value={newMaData.personalnummer} style={{width:"95%"}}/>
        <br/>
        <label className="form-label mx-3" htmlFor="vorname">Vorname</label>
        <input id="vorname" name="vorname" type="text" className="form-control mx-3" onChange={inputHandler} value={newMaData.vorname}  style={{width:"95%"}} />
        <br/> 
        <label className="form-label mx-3" htmlFor="Nachname">Nachname</label>
        <input id="nachname" name="nachname" type="text" className="form-control mx-3" onChange={inputHandler} value={newMaData.nachname}  style={{width:"95%"}}/>
        <br/> 
        <label className="form-label mx-3" htmlFor="Email">E-Mail</label>
        <input id="email" name="email" type="text" className="form-control mx-3" onChange={inputHandler} value={newMaData.email}  style={{width:"95%"}}/>
        <br/> 
        <label className="form-label mx-3" htmlFor="abteilung">Abteilung</label>
        <input id="abteilung" name="abteilung" type="text" className="form-control mx-3" onChange={inputHandler} value={newMaData.abteilung}  style={{width:"95%"}}/>
        <br/> 
        <label className="form-label mx-3" htmlFor="Standort">Standort</label>
        <input id="standort" name="standort" type="text" className="form-control mx-3" onChange={inputHandler} value={newMaData.standort}  style={{width:"95%"}}/>
        
        <div className="text-center">
            <button className="btn btn-outline-light btn-lg mt-5 mb-3" onClick={() => submitHandler()}>Mitarbeiter Anlegen</button>
        </div>
    </div>
)}

export default InsertSingelMa