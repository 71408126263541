import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import FileInput from '../components/FileInput'
import H3Überschrift from '../components/H3Überschrift'
import LoadingMessages from '../enums/LoadingMessages'
import LoadingState from '../enums/LoadingState'
import Messages from '../enums/Messages'
import WackenhutSchriftZug from '../includes/img/wackenhut.png'
import Get_JWT_Payload from '../logic/Get_JWT_Payload'


const Admineingabe = () => {

    const cookies = new Cookies()
    const navigate =useNavigate()
    const token = cookies.get('token')
    
    const[showInput,setShowInput]= useState('0')
    const [sendFiles,setSendFiles] = useState(null)
    const [loading,setLoading] = useState({state:LoadingState.Inactive,text:LoadingMessages.CheckAuthorization})
    const [displayInp ,setDisplayInp] =useState({display:'none'})
    const [lkwData,setLkwData] = useState({lkw:'nein',date:''})
    const [pkwData,setPkwData] = useState({date:''})
    const [checkboxState,setCheckboxState] = useState(false)


    const selectChangeHandler = (e) => {
        setShowInput(e.target.value)
        setDisplayInp({display:'none'})
        setSendFiles(null)
    }

    const fileHandler = (e)=> {
        if(e.target.files[0].size > 7500000){
            alert("Die Datei darf nicht größer als 7,5mb sein")
            return
        }
        const x = {...sendFiles, [e.target.getAttribute('name')]:e.target.files[0]}
        setSendFiles(x)
        }
    

        
    const submitData = () => {
        if(checkboxState === true){
            setLoading({state:LoadingState.Active,text:LoadingMessages.SendData})
            const formData = new FormData()
            formData.append('keinFS',checkboxState)
            formData.append('personalnummer',Get_JWT_Payload('personalnummer'))
            formData.append('choosenMode',showInput)
            formData.append('pkwDate',pkwData.date)

            axios.post(process.env.REACT_APP_BASE_URL + 'filesUpload/filesUpload.php',formData, {headers: {'Authorization':"Bearer " + token, "ContentType": "multipart/form-data"}})
            .then( () => {
                setLoading({...loading,state:LoadingState.Inactive})
                setSendFiles(null)
                alert('Erfolgreich eingefügt')
                navigate('/adminBackend')
            })
            .catch(error =>{
                console.log(error)
                if(error.response.status === 401){
                    }else{
                    alert(Messages.error.message)
                    setLoading({...loading,state:LoadingState.Inactive})
                    }
            })
        }else{
            setLoading({state:LoadingState.Active,text:LoadingMessages.SendData})
            const formData = new FormData()
            formData.append('personalnummer',Get_JWT_Payload('personalnummer'))
            formData.append('choosenMode',showInput)
            formData.append('lkw',lkwData.lkw)
            formData.append('lkwDate',lkwData.date)
            formData.append('pkwDate',pkwData.date)
            formData.append('keinFS',checkboxState)
            
            const keys=Object.keys(sendFiles)
            
            keys.forEach((key)=> {
                formData.append(key,sendFiles[key])
            })

            axios.post(process.env.REACT_APP_BASE_URL + 'filesUpload/filesUpload.php',formData, {headers: {'Authorization':"Bearer " + token, "ContentType": "multipart/form-data"}})
            .then( () => {
                setLoading({...loading,state:LoadingState.Inactive})
                setSendFiles(null)
                alert('Erfolgreich eingefügt')
                navigate('/')
            })
            .catch(error =>{
                console.log(error)
                if(error.response.status === 401){
                    }else{
                    alert(Messages.error.message)
                    setLoading({...loading,state:LoadingState.Inactive})
                    }
            })
        }
    }

    const radioHandler =(e)=> {
            if(e.target.value === 'ja'){
            setDisplayInp({display: ''})
            setLkwData({...lkwData,lkw:'ja'})
            }else{
            setDisplayInp({display: 'none'})
            setLkwData({...lkwData,lkw:'nein',date:''})
            }
        }
        
        const dateHandler =(e,mode)=> {
            switch (mode) {
                case 'lkw':
                    setLkwData({...lkwData,date:e.target.value})
                    break;
                case 'pkw':
                    setPkwData({...pkwData,date:e.target.value})
                    break;
                default:
                    break;
            }
        }

    return (
    <>
    <div className="container">
        <div className="bg-dark text-center mt-5 mb-3" style={{marginLeft:'8%' , width:'85%'}}> 
            <div className="text-center">
                <img src={WackenhutSchriftZug} alt="Wackenhut Schriftzug" className="img-fluid mt-4" />
                <h1 className="text-white mt-3 headerÜberschrift">Führerscheinnachweis App</h1>
            </div>
            <div>
            <p className="p-Blocksatz mt-4 m-5 text-white">
                Liebe Kolleginnen und Kollegen,<br/>
                <br/>
                wir sind gesetzlich dazu verpflichtet, die Gültigkeit der Fahrerlaubnis aller Mitarbeiter*innen zu prüfen.<br/>
                Dieser Nachweispflicht möchten wir, in einer eigens dafür entwickelten App, für Sie einfach und schnell nachkommen. <br/>
                <br/>
                Bitte laden Sie ein <span className="fw-bold">aktuelles, farbiges, gut lesbares</span> Bild Ihres Führerscheins (Vorder- und Rückseite) in der App hoch. Fotografieren Sie bitte das aktuelle Tagesdatum z.B. von
                einer Tageszeitung, PC, Handy o.ä. mit ab, um die Aktualität des Führerscheines nachzuweisen.<br/>
                <br/>

                Sollten Sie im Besitz eines LKW-Führerscheins sein, geben Sie bitte zusätzlich die geforderten Daten, wie das Ablaufdatum des Führerscheins und die Führerscheinklasse an. <br/>
                <br/>
                Sie werden zukünftig an den zweimal jährlich erfolgenden Upload per Mail erinnert und wir bitten Sie, diesem auch nachzukommen.<br/>
                <br/>
                Hiermit entfällt die persönliche Kontrolle durch unsere Mitarbeiter.<br/>

                Unabhängig, der routinemäßigen Kontrolle möchten wir Sie daran erinnern, dass jeglicher Verlust ihrer Fahrerlaubnis umgehend an ihren Vorgesetzten zu melden ist.<br/>
                <br/>
                Selbstverständlich werden Ihre Daten im Rahmen des Datenschutzgesetzes sensibel behandelt und nicht an Dritte weitergeleitet. <br/>
                <br/>
                <br/>
                Herzlichen Dank für Ihre Mithilfe bei der Umsetzung. <br/>
                <br/>
                Ihre HR-Abteilung<br/>
            </p>
            <hr style={{color: 'white'}} />
            <div className="mt-4">
                <div>
                <h4 className="h4Ueberschrift" >Was für einen Führerschein besitzen Sie ?</h4>
                <select className="form-select form-select-md" aria-label="Default select example" onChange={e => selectChangeHandler(e)} defaultValue={{value: '0' }} style={{marginLeft:'20%',  width:'60%'}}>
                    <option value="0" defaultValue >Bitte auswählen</option>
                    <option value="2" >PKW( *optional mit LKW) </option>
                    <option value="1" >PKW( *optional mit LKW) + Flurförderfahrzeug </option>
                    <option value="3" >Flurförderfahrzeug( ausschließlich für E-wagen, Stapler, etc... )</option>
                    <option value="4" >Kein Führerschein</option>
                </select>
            </div>
            <br/>
            <br/>
            <div>
                    {showInput === "1" && <><H3Überschrift text={'Kraftfahrzeug'} />
                                            <h6 className="text-white mt-4">Besitzen Sie einen LKW-Führerschein?</h6>
                                            <div className="parent">
                                                <div className="column">
                                                <label className="con1" style={{marginRight:'10%'}}>
                                                    <label for="no" className="text-white" >Nein</label>
                                                    <input type="radio" name="radio1" value="nein" defaultChecked onChange={(e)=> radioHandler(e)} id="no" style={{marginTop:'3px'}}/>
                                                </label>
                                                </div>
                                                <div className="column">
                                                <label className="con1" style={{marginRight:'10%', float: 'none'}}>
                                                    <label for="yes" className="text-white" >Ja</label>
                                                    <input type="radio" name="radio1" value="ja" onChange={(e)=> radioHandler(e)} id="yes" style={{marginTop:'3px'}}/>
                                                </label>
                                                </div>
                                            </div>
                                            
                                            <div id="dateInp" style={displayInp}>
                                                <h6 className="text-white">Wann ist das Ablaufdatum Ihres LKW-Führerscheins</h6>
                                                <input className="form-control"  type="date" style={{marginLeft:'20%',  width:'60%'}}  onChange={(e)=>dateHandler(e,'lkw')}/>
                                            </div>
                                            <br/>
                                            <FileInput seite={'Vorderseite'} führerschein={'Führerschein'} name={"KFZvorderseite" } fileHandler={fileHandler} id={"KFZvorderseite"} />
                                            <FileInput seite={'Rückseite'} führerschein={'Führerschein'} name={"KFZrueckseite" } fileHandler={fileHandler} id={"KFZrueckseite"}/>
                                            <br/>
                                            <div id="dateInp">
                                                <h6 className="text-white">Wann ist das Ablaufdatum Ihres PKW-Führerscheins? *optional</h6>
                                                <input className="form-control" id="dateInp" type="date" style={{marginLeft:'20%',  width:'60%'}}  onChange={(e)=>dateHandler(e,'pkw')}/>
                                            </div>
                                            <br/>
                                            <H3Überschrift text={'Flurförderfahrzeug'} />
                                            <FileInput seite={'Vorderseite'} führerschein={'Flurförderschein'} name={"FFFZvorderseite" } fileHandler={fileHandler} id={"FFFZvorderseite"}/>
                                            <FileInput seite={'Rückseite'} führerschein={'Flurförderschein'} name={"FFFZrueckseite" } fileHandler={fileHandler} id={"FFFZrueckseite"}/>
                                            </> }

                    {showInput ==="2" && <>
                                            <H3Überschrift text={'KFZ Führerschein'} />
                                            <h6 className="text-white mt-4">Besitzen Sie einen LKW-Führerschein?</h6>
                                            <div className="parent">
                                                <div className="column">
                                                <label className="con1" style={{marginRight:'10%'}}>
                                                    <label for="no" className="text-white" >Nein</label>
                                                    <input type="radio" name="radio1" value="nein" defaultChecked onChange={(e)=> radioHandler(e)} id="no" style={{marginTop:'3px'}}/>
                                                </label>
                                                </div>
                                                <div className="column">
                                                <label className="con1" style={{marginRight:'10%', float: 'none'}}>
                                                    <label for="yes" className="text-white" >Ja</label>
                                                    <input type="radio" name="radio1" value="ja" onChange={(e)=> radioHandler(e)} id="yes" style={{marginTop:'3px'}}/>
                                                </label>
                                                </div>
                                            </div> 
                                            <br/>
                                            <div id="dateInp"  style={displayInp}>
                                                <h6 className="text-white">Wann ist das Ablaufdatum Ihres LKW-Führerscheins?</h6>
                                                <input className="form-control" id="dateInp" type="date" style={{marginLeft:'20%',  width:'60%'}}  onChange={(e)=>dateHandler(e,'lkw')}/>
                                            </div>
                                            <br/>
                                            <FileInput seite={'Vorderseite'} führerschein={'Führerschein'} name={"KFZvorderseite" } fileHandler={fileHandler} id={"KFZvorderseite"} />
                                            <FileInput seite={'Rückseite'} führerschein={'Führerschein'} name={"KFZrueckseite" } fileHandler={fileHandler} id={"KFZrueckseite"}/>
                                            <br/>
                                            <div id="dateInp">
                                                <h6 className="text-white">Wann ist das Ablaufdatum Ihres PKW-Führerscheins? *optional</h6>
                                                <input className="form-control" id="dateInp" type="date" style={{marginLeft:'20%',  width:'60%'}}  onChange={(e)=>dateHandler(e,'pkw')}/>
                                            </div>
                                    </>}
                                    
                {showInput ==="3" && <>
                                            <H3Überschrift text={'Flurförderfahrzeug'} />
                                            <FileInput seite={'Vorderseite'} führerschein={'Flurförderschein'} name={"FFFZvorderseite"} id={"FFFZvorderseite"} fileHandler={fileHandler} />
                                            <FileInput seite={'Rückseite'} führerschein={'Flurförderschein'} name={"FFFZrueckseite"} id={"FFFZrueckseite"} fileHandler={fileHandler} />
                                    </>}                      
                {showInput ==="4" && <>
                                            <div>
                                                <input name="checkbox" type="checkbox" required onClick={()=>setCheckboxState(!checkboxState)}/>
                                                <label style={{ marginLeft:'5px'}} htmlFor="checkbox" className="text-white">Ich besitze keinen Führerschein </label>
                                            </div>
                                    </>}                      
            </div>
            </div>
            {showInput !== '0' && <>
                                    <button className="btn btn-outline-light btn-lg mt-5 mb-3" onClick={submitData}>Absenden</button>
                                    <br/>
                                </>}
            </div>
        </div> 
    </div>
    </>
    )
}

export default Admineingabe