import React from 'react'

const ErrorAlert = (props) => {
    const {text,className} = props
  return (
    <div className="alert alert-danger" role="alert">
        {text}
    </div>
  )
}

export default ErrorAlert