import Cookies from 'universal-cookie'

const Get_JWT_Payload = (data) => {

    const cookies = new Cookies()
    const JWT= cookies.get('token')
    const splittedJWT = JWT.split('.')
    const payloadJWT =JSON.parse(atob(splittedJWT[1]))

    return payloadJWT[data]

}

export default Get_JWT_Payload