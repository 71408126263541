import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import LoadingMessages from "../enums/LoadingMessages";
import LoadingState from "../enums/LoadingState";
import Messages from "../enums/Messages";
import LoadingScreen from "./LoadingScreen";

const AdminDeleteMa = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");

  const [getMaData, setGetMaData] = useState();
  const [deleteMaData, setDeleteMaData] = useState({ personalnummer: "", vorname: "", nachname: "" });
  const [loading, setLoading] = useState({ state: LoadingState.Inactive, text: LoadingMessages.CheckAuthorization });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "deleteMa/deleteMa.php", { headers: { Authorization: "Bearer " + token } })
      .then((response) => {
        setGetMaData(response.data);
      })
      .catch((error) => {
        console.log(error);
        alert(Messages.error.message);
      });
  }, []);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setDeleteMaData({ ...deleteMaData, [name]: value });
  };

  const submitHandler = () => {
    setLoading({ state: LoadingState.Active, text: LoadingMessages.SendData });
    const filterdRes = getMaData.filter((el) => el.personalnummer === deleteMaData.personalnummer);

    const getData = filterdRes[0];

    if (
      getData &&
      deleteMaData.personalnummer.trim() === getData.personalnummer.trim() &&
      deleteMaData.vorname.toLowerCase().trim() === getData.vorname.toLowerCase().trim() &&
      deleteMaData.nachname.toLowerCase().trim() === getData.nachname.toLowerCase().trim()
    ) {
      axios
        .delete(process.env.REACT_APP_BASE_URL + `/deleteMa/deleteMa.php?pnr=${deleteMaData.personalnummer}`, {
          headers: { Authorization: "Bearer " + token },
        })
        .then(() => {
          setLoading({ ...loading, state: LoadingState.Inactive });
          alert("Erfolgreich gelöscht");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            alert("unautorisiert");
            setLoading({ ...loading, state: LoadingState.Inactive });
          } else {
            console.log(error);
            alert(Messages.error.message);
            setLoading({ ...loading, state: LoadingState.Inactive });
          }
        });
      setDeleteMaData({ personalnummer: "", vorname: "", nachname: "" });
    } else {
        console.log(deleteMaData)
        console.log(getData)
        setLoading({ ...loading, state: LoadingState.Inactive });
        alert(Messages.error.message);
    }
  };

  return (
    <>
      {loading.state === LoadingState.Active && <LoadingScreen text={LoadingMessages.GeneralWaiting} />}
      <div className="container">
        <div className="AdminAreaBox ">
          <h1 className="mt-4 text-center headerÜberschriftAdminArea">Admin Lounge</h1>
          <h2 className="mt-2 text-center H2-Dashboard">Ma-Löschen</h2>
        </div>
        <div>
          <h3 className="mt-4 text-white">Bitte Füllen Sie die Daten aus</h3>
          <div className="text-white bg-dark mb-3 " style={{ borderRadius: "10px" }}>
            <br />
            <label className="form-label mx-3" htmlFor="personalnummer">
              Personalnummer
            </label>
            <input
              id="personalnummer"
              name="personalnummer"
              type="text"
              className="form-control mx-3"
              onChange={inputHandler}
              value={deleteMaData.personalnummer}
              style={{ width: "95%" }}
            />
            <br />
            <label className="form-label mx-3" htmlFor="vorname">
              Vorname
            </label>
            <input
              id="vorname"
              name="vorname"
              type="text"
              className="form-control mx-3"
              onChange={inputHandler}
              value={deleteMaData.vorname}
              style={{ width: "95%" }}
            />
            <br />
            <label className="form-label mx-3" htmlFor="Nachname">
              Nachname
            </label>
            <input
              id="nachname"
              name="nachname"
              type="text"
              className="form-control mx-3"
              onChange={inputHandler}
              value={deleteMaData.nachname}
              style={{ width: "95%" }}
            />
            <br />
            <div className="text-center">
              <button className="btn btn-outline-light btn-lg mt-5 mb-3" onClick={() => submitHandler()}>
                Mitarbeiter Löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDeleteMa;
