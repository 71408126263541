import React from 'react'
import LoadingMessages from '../../../enums/LoadingMessages'
import LoadingState from '../../../enums/LoadingState'
import LoadingScreen from '../../LoadingScreen'

const InsertMultiMa = (props) => {
    const {fileHandler,submit,resData,loading} = props


return (
    <div>
        {loading.state === LoadingState.Active && <LoadingScreen text={loading.text}/>}
        <h1 className="text-whit text-center">Eingabe Mitarbeiter via CSV</h1>
        <div className="mt-2">
            <h6 className="text-white mt-3"></h6>
            <input type="file" name='importCSV' id="importCSV-Handler" className="form-control mb-3" accept="image/*;capture=camera" onChange={fileHandler}  style={{marginLeft:'20%',  width:'60%'}}/>
        </div>

        {resData ? 
        <div>
          <h1 className="text-whit text-center mb-5" style={{textDecoration:'underline'}}>Import abgeschlossen</h1>
          <br />
          <h3 className='mx-3'>Es wurden <span className="fw-bold">{resData.eingelesen}</span> Datensätze eingelesen.</h3>
          <h3 className='mx-3'>Davon wurden <span className="fw-bold mt-2 mb-2">{resData.inserted}</span> Datensätze eingetragen.</h3>
          <h3 className='mx-3'>Doppelte Personalnummern entdeckt: <span className="fw-bold">{resData.double ? resData.double :0} stck</span></h3>
          <br/>
        </div>
        :
        <div className="ml" >
          <div className="col-md-8">
            <h4>Anleitung:</h4>
            <h6>Liste mit Mitarbeiterdaten in Excel erstellen .</h6>
            <h6>2.Document Speichern als CSV UTF-8(durch Trennzeichen getrennt)(*.csv) </h6>
            <h6>3.In das Feld klicken und zum importieren gewünschte CSV-Datei auswählen .</h6>
            <h6>4.Auf Importieren Klicken und das Ergebnis abwarten !</h6>
            <div className="text-center mt-2">
              <button className="btn btn-outline-light btn-lg mt-5 mb-5" onClick={()=>submit()}>CSV Importieren</button>
            </div>
          </div>
        </div>
        }
    </div>
  )
}

export default InsertMultiMa