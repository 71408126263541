const LoadingMessages = {
    Login: 'Ihre Anmeldedaten werden geprüft . . .',
    GeneralWaiting: 'Bitte warten. . .',
    GeneralWaiting2: 'Bitte warten Bild wird geladen. . .',
    GeneralWaiting3: 'Bitte warten die Daten werden geladen. . .',
    CheckAuthorization:'Autorisierung wird geprüft. . .',
    SendData: 'Ihre Daten werden übermittelt. . .',
    SendData2: 'Ihre Daten werden übermittelt und verarbeitet . . .'
}

export default LoadingMessages