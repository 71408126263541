import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import ErrorAlert from '../components/ErrorAlert'
import LoadingState from '../enums/LoadingState'
import Messages from '../enums/Messages'
import Get_JWT_Payload from '../logic/Get_JWT_Payload'
import { Validation } from '../logic/Validation'
import { useNavigate } from "react-router-dom";
import LoadingScreen from '../components/LoadingScreen'
import LoadingMessages from '../enums/LoadingMessages'
import axios from 'axios'

const ChangePW = () => {

    const [loading,setLoading] = useState({state:LoadingState.Inactive,text:LoadingMessages.CheckAuthorization})
    const [newPW,setNewPW] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [sendData,setSendData] = useState({personalnummer:'',passwort:''})
    const [passwordShown,setPasswordShown] = useState(false)

    const navigate =useNavigate()
    const cookies = new Cookies()
    const token = cookies.get('token')

    const regPW = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).{6,}$/
    const regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    useEffect(() => {
        (async()=>{
            setLoading({state:LoadingState.Active,text:LoadingMessages.CheckAuthorization})
            try{
            await Validation()
            if(Get_JWT_Payload('defaultPW')===false){
                if(Get_JWT_Payload('admin')==='1'){
                    navigate('/adminBackend')
                }else{
                    navigate('/home')
                }
            }
            else{
                navigate('/changePassword')
            }
            setLoading({...loading,state:LoadingState.Inactive})
        } catch (error) {
            setLoading({...loading,state:LoadingState.Inactive})
            navigate('/')
        }
        })() 
    }, [])

    const submitPW = () => {
        setLoading({state:LoadingState.Active,text:LoadingMessages.SendData})
        const finalSendData = {...sendData,email:newPW.Email,passwort:newPW.newPW,personalnummer:Get_JWT_Payload('personalnummer')}
        setSendData(finalSendData)
        
        if(newPW.newPW === newPW.newPW_Again){
            const regExPW = regPW
            const regExEmail = regEmail
            if(!regExPW.test(newPW.newPW)){
                setErrorMessage(Messages.regEx_failed)
                setLoading({...loading,state:LoadingState.Inactive})
                return
            }else if(!regExEmail.test(newPW.Email)){
                setErrorMessage(Messages.email_failed)
                setLoading({...loading,state:LoadingState.Inactive})
                return
            }
            axios.put(process.env.REACT_APP_BASE_URL + 'changePassword/changePassword.php',finalSendData, {headers: {'Authorization':"Bearer " + token}})
            .then( () => {
                setLoading({...loading,state:LoadingState.Inactive})
                cookies.remove("token", {path: "/"})
                navigate("/")
            })
            .catch(error =>{
                if(error.response.status === 401){
                    navigate('/')
                    }else{
                    setErrorMessage(Messages.error)
                    setLoading({...loading,state:LoadingState.Inactive})
                    }
            })
        }else{
            setLoading({...loading,state:LoadingState.Inactive})
            setErrorMessage(Messages.PW_not_equal)
        }
    }


    const inputHandler = (e) => {
        const {name,value} = e.target
        setNewPW({...newPW,[name]:value})
    }

    const showPassword = () => {
        setPasswordShown(!passwordShown)
        }

    return (
        <>
        {loading.state === LoadingState.Active && <LoadingScreen text={LoadingMessages.GeneralWaiting}/>}
        <div className="container">
            {errorMessage && <ErrorAlert text={"Error: "+errorMessage.message+' ('+errorMessage.code+')'}/>}  
            <div className="pwdBox mt-5">
                <h1 className="mt-3 text-center headerÜberschriftChangePWD">Passwortneuvergabe</h1>
                <p className="mt-3 text-center header text-white">Aus Sicherheitsgründen müssen Sie ein neues Passwort vergeben!</p>
                <p className="text-center header text-white">Beachten Sie bitte das Ihr Kennwort mindestens 6 Zeichen lang sein muss und mindestens eine Zahl, ein Sonderzeichen und Groß/Kleinschreibung beinhalten muss</p>
                <div className="text-center mt-2">
                    <label className="mt-3 form-label text-white" htmlFor="newPW" style={{float:"Left",marginLeft:"2%"}}>Neues Passwort</label>
                    <div className="input-group">
                        <input className="form-control" type={passwordShown ? "text":"password"} name="newPW" id="newPW" placeholder="Passwort" onChange={inputHandler} style={{marginLeft:"2%",width:"50%"}} />
                        <span className="input-group-text" id="basic-addon2"><i className={`bi ${passwordShown ? "bi-eye-slash-fill":"bi-eye-fill"}`} onClick={showPassword}></i></span>
                    </div>

                    <label className="mt-3 form-label text-white" htmlFor="newPW_Again" style={{float:"Left",marginLeft:"2%"}} >Neues Passwort wiederholen</label>
                    <div className="input-group">
                        <input className="form-control" type={passwordShown ? "text":"password"} name="newPW_Again" id="newPW_Again" placeholder="Neues Passwort wiederholen" onChange={inputHandler} style={{marginLeft:"2%" ,width:"50%"}}/>
                        <span className="input-group-text" id="basic-addon2"><i className={`bi ${passwordShown ? "bi-eye-slash-fill":"bi-eye-fill"}`} onClick={showPassword}></i></span>
                    </div>

                    <label className="mt-3 form-label text-white" htmlFor="email" style={{float:"Left",marginLeft:"2%"}} >Ihre bevorzugte E-mail Adresse für Benachrichtungen</label>
                    <input className="form-control mt-3" type="text" name="Email" id="email" placeholder="E-mail" onChange={inputHandler} style={{marginLeft:"2%" ,width:"95%"}}/>
                </div>
                <button className="btn btn-outline-light btn-lg mt-4 mb-4" onClick={submitPW}>Absenden</button>
            </div>
            
        </div>
    </>
    )
}

export default ChangePW