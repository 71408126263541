import React from 'react'

const FileInput = (props) => {
    const {seite,führerschein,name,id,fileHandler} = props

  return (
    <>
      <h6 className="text-white mt-3">Bitte Laden Sie hier die {seite} Ihres {führerschein} hoch</h6>
      <input type="file" name={name} id={id} className="form-control" accept="image/*;capture=camera" onChange={fileHandler}  style={{marginLeft:'20%',  width:'60%'}}/>
    </>
  )
}

export default FileInput