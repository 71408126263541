import React, { useEffect, useState } from "react";
import ErrorAlert from "../components/ErrorAlert";
import LoadingScreen from "../components/LoadingScreen";
import LoadingMessages from "../enums/LoadingMessages";
import LoadingState from "../enums/LoadingState";
import Messages from "../enums/Messages";
import wackenhutLogo from "../includes/img/wackenhut.png";
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useNavigate } from "react-router-dom";
import Get_JWT_Payload from "../logic/Get_JWT_Payload";
import { Validation } from "../logic/Validation";

const Loginpage = () => {

    const navigate =useNavigate()
    const cookies = new Cookies()
    const token = cookies.get('token')
    const [loading,setLoading] = useState({state:LoadingState.Inactive,text:LoadingMessages.CheckAuthorization})
    const [errorMessage, setErrorMessage] = useState(null)
    const [loginData,setLoginData]= useState({user:'',password:''})
    const [passwordShown,setPasswordShown] = useState(false)

useEffect(() => {
    (async()=>{
        try{
            setLoading({state:LoadingState.Active,text:LoadingMessages.CheckAuthorization})
            await Validation()
            if(Get_JWT_Payload('admin')==='1'){
                navigate('/adminBackend')
            }else if(Get_JWT_Payload('admin')==='0'){
                navigate('/home')
            }
            setLoading({...loading,state:LoadingState.Inactive})
    }   catch (error) {
            setLoading({...loading,state:LoadingState.Inactive})
            navigate('/')
    }
    })() 
}, [])    


    const changeHandler = (e) => {
        const {name,value} = e.target
        setLoginData({...loginData,[name]:value})
    }

    const submitHandler = () =>{
        setLoading({state:LoadingState.Active,text:LoadingMessages.Login})
        axios.post(process.env.REACT_APP_BASE_URL + 'login/login.php',loginData, {headers: {'Authorization':"Bearer "+token}})
        .then((response) => {
                const options = {
                // HttpOnly: true, //TODO im Online Betrieb auskommentieren
                // secure: true,
                path: '/',
                expires: new Date(Date.now() + 1000*60*60*5)
                }
            cookies.set("token",response.data,options)

            if(Get_JWT_Payload('defaultPW')===true){
                setLoading({...loading,state:LoadingState.Inactive})
                navigate('/changePassword')
            }else{
                if(Get_JWT_Payload('admin')==='1'){
                    setLoading({...loading,state:LoadingState.Inactive})
                    navigate('/adminBackend')
                }else{
                    setLoading({...loading,state:LoadingState.Inactive})
                    navigate('/home')
                }
            }
        })
        .catch((error) => {
            setLoading({...loading,state:LoadingState.Inactive})
            setErrorMessage(Messages.loginFailed)
        })
    }


    const showPassword = () => {
        setPasswordShown(!passwordShown)
        }

    const requestPwReset = () => {
        if(loginData.user === ""){
            alert("Bitte geben Sie Ihre Personalnummer an")
            return
        }

        axios.post(process.env.REACT_APP_BASE_URL + "resetPassword/resetPassword.php", {personalnummer: loginData.user})
        .then(res=>{
            console.log(res.data)
            alert("Wenn Ihre Personalnummer vorhanden ist, erhalten Sie eine Email mit Ihrem Reset Link")
        })
        .catch(err=>console.log(err))
    }

    return (
        <>
        {loading.state === LoadingState.Active && <LoadingScreen text={loading.text}/>}
        {errorMessage && <ErrorAlert text={"Error: "+errorMessage.message+' ('+errorMessage.code+')'}/>}
        <section className="vh-100 gradient-custom">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-dark text-white" style={{borderRadius: '1rem'}}>
                            <div className="card-body p-5 text-center">
                                <div className="mb-md-5 mt-md-4 pb-5">
                                    <img src={wackenhutLogo} alt="Wackenhut Schriftzug" className="img-fluid" />
                                    <br />
                                    <br />
                                    <h2 className="fw-bold mb-2 text-uppercase">Anmeldung</h2>
                                    <p className="text-white-50 mb-5">Bitte geben Sie Ihre Personalnummer und Ihr Kennwort ein.</p>
                                    <div className="form-outline form-white mb-4">
                                        <input name="user" type="text" id="Personalnummer" className="form-control" onChange={changeHandler}/>
                                        <label className="form-label" htmlFor="Personalnummer" >Personalnummer</label>
                                    </div>
                                    <div className="form-outline form-white mb-4">
                                        <div className="input-group">
                                        <input type={passwordShown ? "text":"password"} name="password" id="Passwort" className="form-control" onKeyPress={(e) => e.key === 'Enter' && submitHandler()} onChange={changeHandler} />
                                        <span className="input-group-text" id="basic-addon2"><i className={`bi ${passwordShown ? "bi-eye-slash-fill":"bi-eye-fill"}`} onClick={showPassword}></i></span>
                                        </div>
                                        <label className="form-label" htmlFor="Passwort">Passwort</label>
                                    </div>
                                    <button className="btn btn-outline-light btn-lg px-5" id="Login" onClick={submitHandler}>Anmelden</button>
                                </div>
                                    <i style={{color:'lightblue', cursor:'pointer'}} onClick={requestPwReset}>Passwort vergessen</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        </>
        );
};

export default Loginpage;

//btoa