import React, { useEffect, useState } from 'react';
import { MDBDataTableV5  } from 'mdbreact';
import ExportToExcel from '../../logic/ExportToExcel';

const OldNachweiseDatatable = (props) => {

    const {receivedData ,pagenum}= props
    const [datatable,setDatatable] = useState(null)
    const [filenameEndung,setFilename] = useState()





    

const exportToExcel =async () => {
    const columns = [
        { header: 'personalnummer', key: 'personalnummer' },
        { header: 'Führerscheinart', key: 'mode' },
        { header: 'lkw', key: 'lkw' },
        { header: 'vorname', key: 'vorname' },
        { header: 'nachname', key: 'nachname' },
        { header: 'abteilung', key: 'abteilung'},
        { header: 'standort', key: 'standort' },
        { header: 'LKW Verfall', key: 'lkwdate'},
        { header: 'Letzter Upload', key: 'lastupload'},
    ];

    return await ExportToExcel(receivedData,columns,filenameEndung)
    };

    useEffect(() => {
        switch (pagenum) {
            case 4:
                setFilename('ALLE_ALTEN_NACHWEISE_')
                break;
            case 6:
                setFilename('ALTE_PKW_NACHWEISE_')
                break;
            case 7:
                setFilename('ALTE_LKW_NACHWEISE_')
                break;
            case 9:
                setFilename('ALTE_FFZ_NACHWEISE_')
                break;
            default:
                break;
        }

        const tableData={
            columns:[
                {
                    label:'Personalnummer',
                    field:'personalnummer',
                    attributes:{
                        'aria-controls':'DataTable',
                        'aria-label':'Personalnummer'
                    },
                },
                {
                    label:'Vorname',
                    field:'vorname'
                },
                {
                    label:'Nachname',
                    field:'nachname'
                },
                {
                    label:'Abteilung',
                    field:'abteilung'
                },
                {
                    label:'Standort',
                    field:'standort'
                },
                {
                    label:'Führerscheinart',
                    field:'mode'
                },
                {
                    label:'LKW',
                    field:'lkw'
                },
                {
                    label:'LKW Ablaufdatum',
                    field:'lkwdate'
                },
                {
                    label:'Letzter Upload',
                    field:'lastupload'
                }
            ],
            rows:receivedData
        }
        setDatatable(tableData)
    },[pagenum,receivedData]
    )
    

    return (
        <>
        
        <button className="mx-2 mb-3 btn btn-info" onClick={exportToExcel}  >Export to Excel</button>

        {datatable &&  <MDBDataTableV5 responsive hover striped entriesOptions={[5,10,15]} entries={5} pagesAmount={4} data={datatable} searchTop searchBottom={false}/> }
        </>
    )
}
export default OldNachweiseDatatable