import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import ErrorAlert from '../components/ErrorAlert'
import LoadingState from '../enums/LoadingState'
import Messages from '../enums/Messages'
import Get_JWT_Payload from '../logic/Get_JWT_Payload'
import { Validation } from '../logic/Validation'
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from '../components/LoadingScreen'
import LoadingMessages from '../enums/LoadingMessages'
import axios from 'axios'

const ResetPW = () => {

    const [loading,setLoading] = useState({state:LoadingState.Inactive,text:LoadingMessages.CheckAuthorization})
    const [newPW,setNewPW] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [sendData,setSendData] = useState({personalnummer:'',passwort:''})
    const [passwordShown,setPasswordShown] = useState(false)

    const navigate =useNavigate()
    const {token} = useParams()

    const regPW = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).{6,}$/
    const regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    useEffect(() => {
        (async()=>{
            setLoading({state:LoadingState.Active,text:LoadingMessages.CheckAuthorization})
            try{
                axios.get(process.env.REACT_APP_BASE_URL + 'resetPassword/resetPassword.php?token=' + token)
                .then((res)=>{
                    console.log(res.data)
                    if(res.data.valid === false){
                        navigate("/")
                        return
                    }
                    setSendData({...sendData, personalnummer: res.data.personalnummer})
                })
            setLoading({...loading,state:LoadingState.Inactive})
        } catch (error) {
            setLoading({...loading,state:LoadingState.Inactive})
            navigate('/')
        }
        })() 
    }, [])

    const submitPW = () => {
        setLoading({state:LoadingState.Active,text:LoadingMessages.SendData})
        const finalSendData = {passwort:newPW.newPW, personalnummer: sendData.personalnummer}
        setSendData(finalSendData)
        
        if(newPW.newPW === newPW.newPW_Again){
            const regExPW = regPW
            if(!regExPW.test(newPW.newPW)){
                setErrorMessage(Messages.regEx_failed)
                setLoading({...loading,state:LoadingState.Inactive})
                return
            }
            axios.put(process.env.REACT_APP_BASE_URL + 'resetPassword/resetPassword.php',finalSendData)
            .then( () => {
                setLoading({...loading,state:LoadingState.Inactive})
                alert("Ihr Passwort wurde geändert, sie werden zum Login weitergeleitet")
                navigate("/")
            })
            .catch(error =>{
                    setErrorMessage(Messages.error)
                    setLoading({...loading,state:LoadingState.Inactive})
            })
        }else{
            setLoading({...loading,state:LoadingState.Inactive})
            setErrorMessage(Messages.PW_not_equal)
        }
    }


    const inputHandler = (e) => {
        const {name,value} = e.target
        setNewPW({...newPW,[name]:value})
    }

    const showPassword = () => {
        setPasswordShown(!passwordShown)
        }

    return (
        <>
        {loading.state === LoadingState.Active && <LoadingScreen text={LoadingMessages.GeneralWaiting}/>}
        <div className="container">
            {errorMessage && <ErrorAlert text={"Error: "+errorMessage.message+' ('+errorMessage.code+')'}/>}  
            <div className="pwdBox mt-5">
                <h1 className="mt-3 text-center headerÜberschriftChangePWD">Passwortneuvergabe</h1>
                <p className="mt-3 text-center header text-white">Aus Sicherheitsgründen müssen Sie ein neues Passwort vergeben!</p>
                <p className="text-center header text-white">Beachten Sie bitte das Ihr Kennwort mindestens 6 Zeichen lang sein muss und mindestens eine Zahl, ein Sonderzeichen und Groß/Kleinschreibung beinhalten muss</p>
                <div className="text-center mt-2">
                    <label className="mt-3 form-label text-white" htmlFor="newPW" style={{float:"Left",marginLeft:"2%"}}>Neues Passwort</label>
                    <div className="input-group">
                        <input className="form-control" type={passwordShown ? "text":"password"} name="newPW" id="newPW" placeholder="Passwort" onChange={inputHandler} style={{marginLeft:"2%",width:"50%"}} />
                        <span className="input-group-text" id="basic-addon2"><i className={`bi ${passwordShown ? "bi-eye-slash-fill":"bi-eye-fill"}`} onClick={showPassword}></i></span>
                    </div>

                    <label className="mt-3 form-label text-white" htmlFor="newPW_Again" style={{float:"Left",marginLeft:"2%"}} >Neues Passwort wiederholen</label>
                    <div className="input-group">
                        <input className="form-control" type={passwordShown ? "text":"password"} name="newPW_Again" id="newPW_Again" placeholder="Neues Passwort wiederholen" onChange={inputHandler} style={{marginLeft:"2%" ,width:"50%"}}/>
                        <span className="input-group-text" id="basic-addon2"><i className={`bi ${passwordShown ? "bi-eye-slash-fill":"bi-eye-fill"}`} onClick={showPassword}></i></span>
                    </div>
                </div>
                <button className="btn btn-outline-light btn-lg mt-4 mb-4" onClick={submitPW}>Absenden</button>
            </div>
            
        </div>
    </>
    )
}

export default ResetPW