const Messages = {
    loginFailed: {code:100,message:'Benutzername oder Passwort falsch'},
    unauthorized: {code:102,message:'no valid token'},
    error:{ code:103, message:'Da ist etwas schief gelaufen, bitte erneut versuchen'},
    PW_not_equal:{ code:104, message:'Das eingebene Passwort stimmt nicht überein!'},
    regEx_failed:{code:105, message:'Das eingebene Passwort muss Sonderzeichen,Groß/Kleinschreibung,Zahlen und mindestens 6 Zeichen enthalten!'},
    email_failed: {code:107, message:'Bitte tragen Sie eine valide Email Adresse ein'},
    success:{code:200, message:'Daten erfolgreich eingefügt!'}
}

export default Messages