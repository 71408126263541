import React from 'react'

const H3Überschrift = (props) => {
    const {text} = props

  return (
    <h3 className="h3-KFZ-FFFZ text-white">{text}</h3>
  )
}

export default H3Überschrift