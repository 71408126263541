import axios from 'axios'
import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import LoadingMessages from '../enums/LoadingMessages'
import LoadingState from '../enums/LoadingState'
import Messages from '../enums/Messages'
import InsertMultiMa from './adminAreaComponents/NewMa/InsertMultiMa'
import InsertSingelMa from './adminAreaComponents/NewMa/InsertSingelMa'
import LoadingScreen from './LoadingScreen'

const AdminMANeu = () => {
    const cookies = new Cookies()
    const token = cookies.get('token')
    const[newMaData,setNewMaData] =useState({personalnummer:'',vorname:'',nachname:'',email:'',abteilung:'',standort:'',passwort:'',admin:''})
    const [loading,setLoading] = useState({state:LoadingState.Inactive,text:LoadingMessages.CheckAuthorization})
    const [showSingMulti,setShowSingMulti]=useState(true)
    const [sendFiles,setSendFiles] = useState(null)
    const [resData,setResData] = useState()



    const inputHandler = (e) => {
        const {name,value} = e.target
        setNewMaData({...newMaData,[name]:value})
    }

    const submitHandler = () => {
        setLoading({state:LoadingState.Active,text:LoadingMessages.CheckAuthorization})
        const sendData = {...newMaData,passwort:newMaData.personalnummer}
        setNewMaData(sendData)

        axios.post(process.env.REACT_APP_BASE_URL+'MaNeuanlage/MaNeuanlage.php',sendData, {headers: {'Authorization':"Bearer " + token}})
        .then( () => {
            
            alert('Erfolgreich eingefügt')
            setNewMaData({personalnummer:'',vorname:'',nachname:'',email:'',abteilung:'',standort:'',passwort:'',admin:''})
            setLoading(LoadingState.Inactive)
        })
        .catch(error =>{
            if(error.response.status === 401){
                }else{
                alert(Messages.error.message)
                setLoading(LoadingState.Inactive)
                }
        })
}

const sliderFunc =()=>{
    setShowSingMulti(!showSingMulti)
    setResData()
}

const fileHandler = (e)=> {
    const x = {...sendFiles, [e.target.getAttribute('name')]:e.target.files[0]}
    setSendFiles(x)
    }

const submitHandlerMultiInsert =()=>{
    setLoading({state:LoadingState.Active,text:LoadingMessages.SendData2})
    const formData = new FormData()
    const keys=Object.keys(sendFiles)

    keys.forEach((key)=> {
        formData.append(key,sendFiles[key])
    })

    axios.post(process.env.REACT_APP_BASE_URL + 'importHandler/importHandler.php',formData, {headers: {'Authorization':"Bearer " + token}})
    .then((response) => {
        setSendFiles(null)
        setLoading({...loading,state:LoadingState.Inactive})
        setResData(response.data)
    })
    .catch(error =>{
        if(error.response.status === 401){
            }else{
            alert(Messages.error.message)
            setLoading({...loading,state:LoadingState.Inactive})
            }
    })
}

return (
        <>
        {loading.state === LoadingState.Active && <LoadingScreen text={loading.text}/>}
        <div className="container">
            <div className="AdminAreaBox ">
                <h1 className="mt-4 text-center headerÜberschriftAdminArea">Admin Lounge</h1>
                <h2 className="mt-2 text-center H2-Dashboard" style={{fontSize:'50px'}}>Mitarbeiter Neu Anlegen</h2>
            </div>
            <div className="text-white bg-dark mb-3 " style={{borderRadius:"10px"}}>
                <br/>
                <div className="m-3 form-check form-switch">
                    <input className="mt-1 form-check-input" type="checkbox" onChange={()=>sliderFunc()} role="switch" id="flexSwitchCheckDefault" />
                    <label className="mt-1 mb-2 form-check-label" htmlFor="flexSwitchCheckDefault">&nbsp;&nbsp;Einzel / Mehrfach Anlage</label>
                </div>
                {showSingMulti ===true && <InsertSingelMa  newMaData={newMaData} inputHandler={inputHandler} submitHandler={submitHandler}/> }
                {showSingMulti ===false && <InsertMultiMa fileHandler={fileHandler} submit={submitHandlerMultiInsert} resData={resData} loading={loading}/>}
            </div>
        </div>
        </>
    )
}

export default AdminMANeu