import React, { useEffect, useState } from 'react';
import {MDBDataTableV5  } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import ExportToExcel from '../../logic/ExportToExcel';


const FFZ_Datatable = (props) => {

    const {receivedData,galopen,setGalOpen}= props
    const [datatable,setDatatable] = useState(null)


    const exportToExcel =async () => {
        const columns = [
            { header: 'personalnummer', key: 'personalnummer' },
            { header: 'Führerscheinart', key: 'mode' },
            { header: 'lkw', key: 'lkw' },
            { header: 'vorname', key: 'vorname' },
            { header: 'nachname', key: 'nachname' },
            { header: 'abteilung', key: 'abteilung'},
            { header: 'standort', key: 'standort' },
            { header: 'LKW Verfall', key: 'lkwdate'},
            { header: 'Letzter Upload', key: 'lastupload'},
        ];

        return await ExportToExcel(receivedData,columns,'FFZ_FS_')
    };

    useEffect(() => {
        const tableData={
            columns:[
                {
                    label:'Personalnummer',
                    field:'personalnummer',
                    attributes:{
                        'aria-controls':'DataTable',
                        'aria-label':'Personalnummer'
                    },
                },
                {
                    label:'Vorname',
                    field:'vorname'
                },
                {
                    label:'Nachname',
                    field:'nachname'
                },
                {
                    label:'Abteilung',
                    field:'abteilung'
                },
                {
                    label:'Standort',
                    field:'standort'
                },
                {
                    label:'FFZ-Führerschein Vorderseite',
                    field:'ffzVorder'
                },              
                {
                label:'FFZ-Führerschein Rückseite',
                field:'ffzRueck'
            }
            ],

            rows:receivedData
        }
        setDatatable(tableData)
    },[receivedData]
    )

    return (
        <>
        <button className="mx-2 mb-3 btn btn-info" onClick={exportToExcel}  >Export to Excel</button>

        {datatable &&  <MDBDataTableV5 responsive hover striped entriesOptions={[5,10,15]} entries={5} pagesAmount={4} data={datatable} searchTop searchBottom={false}/> }
        {galopen.gal ? 
    galopen.url.substring(0,10) === 'data:image' ? 
    <Lightbox  image={galopen.url}  onClose={()=>setGalOpen({...galopen,gal:false})}/>
    : <div style={{position:'fixed', top:0, left:0, right:0, height:'100%', backgroundColor:'rgba(0,0,0,0.8)', display:'flex', justifyContent:'center', alignItems:'center'}} onClick={()=>setGalOpen({...galopen,gal:false})}>
        <embed src={galopen.url} type="application/pdf" width='50%' height='80%'/>
    </div>
    : null
    }
    </>
    )
}

export default FFZ_Datatable