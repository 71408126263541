import React, { useEffect, useState } from 'react';
import { MDBDataTableV5  } from 'mdbreact';
import LoadingScreen from '../LoadingScreen';
import LoadingState from '../../enums/LoadingState';
import ExportToExcel from '../../logic/ExportToExcel';

const NO_UPLOAD = (props) => {

    const {receivedData,loading}= props
    const [datatable,setDatatable] = useState(null)


const exportToExcel =async () => {
    const columns = [
        { header: 'personalnummer', key: 'personalnummer' },
        { header: 'vorname', key: 'vorname' },
        { header: 'nachname', key: 'nachname' },
        { header: 'abteilung', key: 'abteilung'},
        { header: 'standort', key: 'standort' },
        { header: 'email', key: 'email' },
    ];

    return await ExportToExcel(receivedData,columns,'KEIN_UPLOAD_')
    };

    useEffect(() => {
        const tableData={
            columns:[
                {
                    label:'Personalnummer',
                    field:'personalnummer',
                    attributes:{
                        'aria-controls':'DataTable',
                        'aria-label':'Personalnummer'
                    },
                },
                {
                    label:'Vorname',
                    field:'vorname'
                },
                {
                    label:'Nachname',
                    field:'nachname'
                },
                {
                    label:'Abteilung',
                    field:'abteilung'
                },
                {
                    label:'Standort',
                    field:'standort'
                },
                {
                    label:'Email',
                    field:'email'
                },
            ],
            rows:receivedData
        }
        setDatatable(tableData)
    },[receivedData]
    )
    

    return (
        <>
        {loading.state === LoadingState.Active && <LoadingScreen text={loading.text}/>}
        <button className="mx-2 mb-3 btn btn-info" onClick={exportToExcel}  >Export to Excel</button>

        {datatable &&  <MDBDataTableV5 responsive hover striped entriesOptions={[5,10]} entries={5} pagesAmount={4} data={datatable} searchTop searchBottom={false}/> }
        </>
    )
}
export default NO_UPLOAD