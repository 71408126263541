import React from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import WHDS from '../includes/img/Logo Wackenhut Digital Solutions weiß.png'


const AdminNavbar = (props) => {

    const {navbarClickHandler}= props

    const navigate =useNavigate()
    const cookies = new Cookies()



    const onClickHandler =(clickedLink) =>{
                navbarClickHandler(clickedLink)
                if (clickedLink==='AdminDashboard'){
                    document.getElementById("dashboard").className = "dropdown-item active"
                    document.getElementById("MA-Neu").className = "dropdown-item"
                    document.getElementById("Delete-Ma").className = "dropdown-item"
                    }
                else if (clickedLink==='AdminMA'){
                    document.getElementById("MA-Neu").className = "dropdown-item active"
                    document.getElementById("dashboard").className = "dropdown-item"
                    document.getElementById("Delete-Ma").className = "dropdown-item"
                    }
                else if (clickedLink==='Delete'){
                    document.getElementById("MA-Neu").className = "dropdown-item "
                    document.getElementById("dashboard").className = "dropdown-item"
                    document.getElementById("Delete-Ma").className = "dropdown-item active"
                    }
                else if (clickedLink==='Führerscheinnachweis'){
                    document.getElementById("MA-Neu").className = "dropdown-item"
                    document.getElementById("dashboard").className = "dropdown-item"
                    document.getElementById("Delete-Ma").className = "dropdown-item"
                }
            }

    const logout = () => {
        cookies.remove('token')
        navigate('/')
    }

    return (
    <div>
    <nav className="navbar navbar-expand-lg navbar-light bg-secondary">
    <div className="container-fluid">
        <span className="navbar-brand text-white" style={{cursor:'default'}}><img src={WHDS} alt="Wackenhut Digital Solution Logo" width="140"></img></span>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
        <li className="nav-item dropdown">
                <span className="nav-link text-white dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">Adminbereich</span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><span className={"dropdown-item"} id="dashboard" onClick={ ()=> onClickHandler('AdminDashboard')} style={{cursor: 'pointer'}}>Dashboard</span></li>
                    <li><span className={"dropdown-item"} id="MA-Neu" onClick={ ()=> onClickHandler('AdminMA')} style={{cursor: 'pointer'}}>Ma Neuanlage</span></li>
                    <li><span className={"dropdown-item"} id="Delete-Ma" onClick={ ()=> onClickHandler('Delete')} style={{cursor: 'pointer'}}>Ma Löschen</span></li>
                </ul>
            </li>
            <li className="nav-item">
                <span className={"nav-link text-white"} onClick={()=>  onClickHandler('Führerscheinnachweis') } style={{cursor: 'pointer'}}>Führerscheinnachweis</span>
            </li>
        </ul>
        </div>
    </div>
    <button className={"btn btn-danger mx-2 "} onClick={logout}>Logout</button>
</nav>
    </div>
    
    )
}

export default AdminNavbar