import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AdminDashboard from '../components/AdminDashboard'
import Admineingabe from '../components/Admineingabe'
import AdminMANeu from '../components/AdminMANeu'
import AdminDeleteMa from '../components/AdminDeleteMa'
import AdminNavbar from '../components/AdminNavbar'
import LoadingScreen from '../components/LoadingScreen'
import LoadingMessages from '../enums/LoadingMessages'
import LoadingState from '../enums/LoadingState'
import Get_JWT_Payload from '../logic/Get_JWT_Payload'
import { Validation } from '../logic/Validation'


const AdminHome = () => {

  const [showArea,setShowArea] = useState('Führerscheinnachweis')
  const navigate =useNavigate()
  const [loading,setLoading] = useState({state:LoadingState.Inactive,text:LoadingMessages.CheckAuthorization})

  useEffect(() => {
    
    (async()=>{
        try{
          setLoading({state:LoadingState.Active,text:LoadingMessages.CheckAuthorization})
            if(Get_JWT_Payload('admin')==='1'){
                await Validation()
                setLoading({...loading,state:LoadingState.Inactive})
            }else{
                navigate('/')
            }
    } catch (error) {
        setLoading({...loading,state:LoadingState.Inactive})
        navigate('/')
    }
    })() 
}, [showArea])


  const navbarClickHandler = (clicked)=>{
      setShowArea(clicked)
  }

  return (
    <>
    {loading.state === LoadingState.Active && <LoadingScreen text={LoadingMessages.Login}/>}
    <AdminNavbar navbarClickHandler={navbarClickHandler}  />
    {showArea === 'Führerscheinnachweis' && <Admineingabe /> }
    {showArea === 'AdminDashboard' && <AdminDashboard /> }
    {showArea === 'AdminMA' && <AdminMANeu />}
    {showArea === 'Delete' && <AdminDeleteMa />}
    </>
  )
}

export default AdminHome