import React, { useEffect, useState } from 'react';
import { MDBDataTableV5  } from 'mdbreact';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import ExportToExcel from '../../logic/ExportToExcel';

const NoFSDatatable = (props) => {

    const {receivedData}= props
    const [datatable,setDatatable] = useState(null)

    



    
const exportToExcel =async () => {
    const columns = [
        { header: 'personalnummer', key: 'personalnummer' },
        { header: 'Führerscheinart', key: 'mode' },
        { header: 'lkw', key: 'lkw' },
        { header: 'vorname', key: 'vorname' },
        { header: 'nachname', key: 'nachname' },
        { header: 'abteilung', key: 'abteilung'},
        { header: 'standort', key: 'standort' },
        { header: 'LKW Verfall', key: 'lkwdate'},
        { header: 'Letzter Upload', key: 'lastupload'},
    ];

    return await ExportToExcel(receivedData,columns,'KEIN_FS_')
    };

    useEffect(() => {
        const tableData={
            columns:[
                {
                    label:'Personalnummer',
                    field:'personalnummer',
                    attributes:{
                        'aria-controls':'DataTable',
                        'aria-label':'Personalnummer'
                    },
                },
                {
                    label:'Vorname',
                    field:'vorname'
                },
                {
                    label:'Nachname',
                    field:'nachname'
                },
                {
                    label:'Abteilung',
                    field:'abteilung'
                },
                {
                    label:'Standort',
                    field:'standort'
                },
                {
                    label:'Führerscheinart',
                    field:'mode'
                },
            ],
            rows:receivedData
        }
        setDatatable(tableData)
    },[receivedData]
    )
    

    return (
        <>
        <button className="mx-2 mb-3 btn btn-info" onClick={exportToExcel}  >Export to Excel</button>

        {datatable &&  <MDBDataTableV5 responsive hover striped entriesOptions={[5,10,15]} entries={5} pagesAmount={4} data={datatable} searchTop searchBottom={false}/> }
        </>
    )
}
export default NoFSDatatable