import '../src/includes/css/index.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import Loginpage from './pages/Loginpage';
import Home from './pages/Home';
import AdminHome from './pages/AdminHome';
import ChangePW from './pages/ChangePW';
import { useEffect } from 'react';
import ResetPW from './pages/ResetPW';


function App() {
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Loginpage    />} exact/>
          <Route path="/home" element={<Home/>} />
          <Route path="/adminBackend" element={<AdminHome/>} />
          <Route path="/ChangePassword" element={<ChangePW />} />
          <Route path="/resetPw/:token" element={<ResetPW/>} />
        </Routes>
      </Router>
  );
}

export default App;
